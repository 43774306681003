/*-------------------------------------------*\
    Window
\*-------------------------------------------*/

:root {
    --title-bar-bg: black;
}
.window.xp {
    font-size: 11px;
    box-shadow: var(--border-window-outer-xp), var(--border-window-inner-xp);
    background: var(--surface-xp);
    padding: 3px;
    fieldset {
        margin-bottom: 9px;
    }
}
.title-bar.xp {
    font-size: 11px;
    background: var(--title-bar-bg);
    padding: 3px 2px 3px 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-bar-text.xp {
    font-weight: bold;
    color: white;
    letter-spacing: 0;
    margin-right: 24px;
}
.title-bar-controls.xp {
    display: flex;
    button {
        padding: 0;
        display: block;
        min-width: 16px;
        min-height: 14px;
        &:focus {
            outline: none;
        }
    }
    button[aria-label="Minimize"] {
    }
    button[aria-label="Maximize"] {
    }
    button[aria-label="Help"] {
    }
    button[aria-label="Close"] {
    }
}
.window-body.xp {
    margin: var(--element-spacing-xp);
    pre {
        margin: -8px;
    }
}

.status-bar.xp {
    margin: 0px 1px;
    display: flex;
    gap: 1px;
}

.status-bar-field.xp {
    box-shadow: var(--border-status-bar-field-xp);
    flex-grow: 1;
    padding: 2px 3px;
    margin: 0;
}
