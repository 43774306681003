/*-------------------------------------------*\
    Buttons
\*-------------------------------------------*/

button.xp {
    font-family: var(--sans-serif-xp);
    -webkit-font-smoothing: antialiased;
    font-size: 11px;
    box-sizing: border-box;
    border: 1px solid #003c74;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(236, 235, 229, 1) 86%,
        rgba(216, 208, 196, 1) 100%
    );
    box-shadow: none;
    border-radius: 3px;
    &:not(:disabled) {
        &:active,
        &.active {
            box-shadow: none;
            background: linear-gradient(
                180deg,
                rgba(205, 202, 195, 1) 0%,
                rgba(227, 227, 219, 1) 8%,
                rgba(229, 229, 222, 1) 94%,
                rgba(242, 242, 241, 1) 100%
            );
        }
        &:hover {
            box-shadow: inset -1px 1px #fff0cf, inset 1px 2px #fdd889, inset -2px 2px #fbc761, inset 2px -2px #e5a01a;
        }
    }
    &:focus,
    &.focused {
        box-shadow: inset -1px 1px #cee7ff, inset 1px 2px #98b8ea, inset -2px 2px #bcd4f6, inset 1px -1px #89ade4,
            inset 2px -2px #89ade4;
    }
    &::-moz-focus-inner {
        border: 0;
    }
}
