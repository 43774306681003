/*-------------------------------------------*\
    Buttons
\*-------------------------------------------*/

button.xp {
    font-family: var(--sans-serif-xp);
    font-size: 11px;
    box-sizing: border-box;
    border: none;
    background: var(--surface-xp);
    box-shadow: var(--border-raised-outer-xp), var(--border-raised-inner-xp);
    border-radius: 0;
    min-width: 75px;
    min-height: 23px;
    padding: 0 12px;
    &:not(:disabled) {
        &:active,
        &.active {
            box-shadow: var(--border-sunken-outer-xp), var(--border-sunken-inner-xp);
        }
    }
    &:focus,
    &.focused {
        outline: 1px dotted #000000;
        outline-offset: -4px;
    }
    &::-moz-focus-inner {
        border: 0;
    }
}
