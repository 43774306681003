/*-------------------------------------------*\
    GroupBox
\*-------------------------------------------*/

fieldset.xp {
    border-image: svg-load("./icon/groupbox-border.svg") 2;
    padding: calc(2 * var(--border-width-xp) + var(--element-spacing-xp));
    padding-block-start: var(--element-spacing-xp);
    margin: 0;
}
legend.xp {
    background: var(--surface-xp);
}
.field-row.xp {
    display: flex;
    align-items: center;
    > * {
        + {
            * {
                margin-left: var(--grouped-element-spacing-xp);
            }
        }
    }
}
[class^="field-row"] {
    + {
        [class^="field-row"] {
            margin-top: var(--grouped-element-spacing-xp);
        }
    }
}
.field-row-stacked.xp {
    display: flex;
    flex-direction: column;
    * {
        + {
            * {
                margin-top: var(--grouped-element-spacing-xp);
            }
        }
    }
}
