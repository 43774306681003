/*-------------------------------------------*\
    Forms
\*-------------------------------------------*/

:root {
    --checkmark-width-xp: 11px;
    --radio-dot-width-xp: 5px;
    --radio-dot-top-xp: 5px;
    --radio-width-xp: 13px;
    --radio-inner-shadow-hover-xp: inset -2px -2px #f8b636, inset 2px 2px #fedf9c;
    --radio-bg-active-xp: linear-gradient(135deg, rgba(176, 176, 167, 1) 0%, rgba(227, 225, 210, 1) 100%);
    --radio-bg-xp: linear-gradient(135deg, rgba(220, 220, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
    --radio-border-xp: 1px solid #1d5281;
    --radio-border-disabled-xp: 1px solid #cac8bb;
}

label.xp,
select.xp,
input.xp,
textarea.xp,
option.xp {
    -webkit-font-smoothing: antialiased;
}

select.xp {
    border: 1px solid #7f9db9;
    background-image: svg-load("./icon/dropdown.svg");
    background-size: 15px;
    &:focus {
        box-shadow: inset 2px 2px white, inset -2px -2px white;
    }
    &:hover {
        background-image: svg-load("./icon/dropdown-hover.svg");
    }
    &:active {
        background-image: svg-load("./icon/dropdown-active.svg");
    }
}

input[type="radio"].xp {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    background: 0;
    position: fixed;
    opacity: 0;
    border: none;
    + {
        label {
            line-height: 16px;
            &::before {
                background: var(--radio-bg-xp);
                border-radius: 50%;
                border: var(--radio-border-xp);
            }
        }
    }
    &:not([disabled]):not(:active) {
        + label {
            &:hover:before {
                box-shadow: var(--radio-inner-shadow-hover-xp);
            }
        }
    }
    &:active {
        + {
            label {
                &::before {
                    background: var(--radio-bg-active-xp);
                }
            }
        }
    }
    &:checked {
        + {
            label {
                &::after {
                    background: svg-load("./icon/radio-dot.svg");
                }
            }
        }
    }
    &:focus {
        + {
            label {
                outline: 1px dotted #000000;
            }
        }
    }
}
input[type="radio"][disabled].xp {
    + {
        label {
            &::before {
                border: var(--radio-border-disabled-xp);
                background: white;
            }
        }
    }
    &:checked {
        + {
            label {
                &::after {
                    background: svg-load("./icon/radio-dot-disabled.svg");
                }
            }
        }
    }
}
input[type="checkbox"].xp {
    + label {
        &:before {
            box-shadow: none;
            border: var(--radio-border-xp);
            background: var(--radio-bg-xp);
        }
    }
    &:checked + label {
        &:after {
            background: svg-load("./icon/checkmark.svg");
            left: -17px;
        }
    }
    &:not([disabled]):not(:active) {
        + label {
            &:hover:before {
                box-shadow: var(--radio-inner-shadow-hover-xp);
            }
        }
    }
    &:active {
        + {
            label {
                &::before {
                    background: var(--radio-bg-active-xp);
                }
            }
        }
    }
}

input[type="checkbox"][disabled].xp {
    + {
        label {
            &::before {
                background: white;
                border: var(--radio-border-disabled-xp);
            }
        }
    }
    &:checked {
        + {
            label {
                &::after {
                    background: svg-load("./icon/checkmark-disabled.svg");
                }
            }
        }
    }
}

input[type="text"].xp,
input[type="password"].xp,
input[type="email"].xp,
select.xp {
    height: 23px;
}

input.xp::selection,
textarea.xp::selection {
    background: var(--dialog-blue-xp);
    color: white;
}

input[type="range"].xp {
    &::-webkit-slider-thumb {
        height: 21px;
        width: 11px;
        background: svg-load("./icon/indicator-horizontal.svg");
        transform: translateY(-8px);
    }
    &::-moz-range-thumb {
        height: 21px;
        width: 11px;
        border: 0;
        border-radius: 0;
        background: svg-load("./icon/indicator-horizontal.svg");
        transform: translateY(2px);
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        box-sizing: border-box;
        background: #ecebe4;
        border-right: 1px solid #f3f2ea;
        border-bottom: 1px solid #f3f2ea;
        border-radius: 2px;
        box-shadow: 1px 0 0 white, 1px 1px 0 white, 0 1px 0 white, -1px 0 0 #9d9c99, -1px -1px 0 #9d9c99,
            0 -1px 0 #9d9c99, -1px 1px 0 white, 1px -1px #9d9c99;
    }
    &::-moz-range-track {
        width: 100%;
        height: 2px;
        box-sizing: border-box;
        background: #ecebe4;
        border-right: 1px solid #f3f2ea;
        border-bottom: 1px solid #f3f2ea;
        border-radius: 2px;
        box-shadow: 1px 0 0 white, 1px 1px 0 white, 0 1px 0 white, -1px 0 0 #9d9c99, -1px -1px 0 #9d9c99,
            0 -1px 0 #9d9c99, -1px 1px 0 white, 1px -1px #9d9c99;
    }
}
input[type="range"].has-box-indicator.xp {
    &::-webkit-slider-thumb {
        background: svg-load("./icon/indicator-rectangle-horizontal.svg");
        transform: translateY(-10px);
    }
    &::-moz-range-thumb {
        background: svg-load("./icon/indicator-rectangle-horizontal.svg");
        transform: translateY(0px);
    }
}
.is-vertical {
    > input[type="range"] {
        &::-webkit-slider-runnable-track {
            border-left: 1px solid #f3f2ea;
            border-right: 0;
            border-bottom: 1px solid #f3f2ea;
            box-shadow: -1px 0 0 white, -1px 1px 0 white, 0 1px 0 white, 1px 0 0 #9d9c99, 1px -1px 0 #9d9c99,
                0 -1px 0 #9d9c99, 1px 1px 0 white, -1px -1px #9d9c99;
        }
        &::-moz-range-track {
            border-left: 1px solid #f3f2ea;
            border-right: 0;
            border-bottom: 1px solid #f3f2ea;
            box-shadow: -1px 0 0 white, -1px 1px 0 white, 0 1px 0 white, 1px 0 0 #9d9c99, 1px -1px 0 #9d9c99,
                0 -1px 0 #9d9c99, 1px 1px 0 white, -1px -1px #9d9c99;
        }
    }
}
